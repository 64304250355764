import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
//content
import GetIcon from '../content/GetIcon'
//scss
import '../../scss/fiche/ficheIcon.scss'
//data
const getData = graphql`
  {
    icon: allContentfulFicheIcon {
      nodes {
        id
        elmIcons {
          title
          subtitle
          icon {
            title
          }
        }
      }
    }
  }
`
const GetFicheIcon = ({ id, title }) => {
  const data = useStaticQuery(getData).icon.nodes.filter((i) => i.id === id)[0]
  return (
    <div className={`ficheIcon`}>
      <h2 className='blockTitle'>{title}</h2>
      <div className='flex'>
        {data.elmIcons
          ? data.elmIcons.map((item, index) => {
              return (
                <div key={index} className='elm'>
                  <GetIcon id={item.icon.title} />

                  <span className='title'>{item.title}</span>
                  <span className='subtitle'>{item.subtitle}</span>
                </div>
              )
            })
          : ''}
      </div>
    </div>
  )
}

export default GetFicheIcon
