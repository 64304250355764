import React from 'react'
import { graphql } from 'gatsby'
//component
import Seo from '../components/SEO'
import ProductTitle from '../components/product/ProductTitle'
import ProductGalery from '../components/product/ProductGalery'
import ProductFiche from '../components/product/ProductFiche'
const TemplateProduct = ({ data, location, pageContext }) => {
  return (
    <>
      <Seo title={pageContext.title} slug={location.pathname} />
      <div className='TemplateProduct'>
        <ProductTitle
          title={data.c.title}
          subtitle={data.c.subtitle}
          divTitle={data.c.productType.subtitle}
          img={data.c.img}
        />
        <ProductGalery galery={data.c.galery} images={data.c.images} />

        <ProductFiche fiche={data.c.fiche} title={data.c.title} />
      </div>
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    c: contentfulProduits(id: { eq: $id }) {
      title
      subtitle
      productType {
        subtitle
      }
      img {
        title
        gatsbyImageData
      }
      galery {
        id
        title
        gatsbyImageData(
          width: 400
          height: 400
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
      fiche {
        ... on ContentfulFicheIcon {
          title
          id
          internal {
            type
          }
        }
        ... on ContentfulFicheText {
          title
          id
          internal {
            type
          }
        }
        ... on ContentfulFicheFile {
          title
          id
          internal {
            type
          }
        }
        ... on ContentfulFicheImg {
          title
          id
          internal {
            type
          }
        }
        ... on ContentfulFicheVideo {
          title
          id
          urls
          internal {
            type
          }
        }
      }

      images: galery {
        id
        title
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
      }
    }
  }
`

export default TemplateProduct
