import React from 'react'
import ReactPlayer from 'react-player'

//content

//scss
import '../../scss/fiche/ficheVideo.scss'
//data

const GetFicheVideo = ({ id, title }) => {
  return (
    <div className={`ficheVideo`}>
      <h2 className='blockTitle'>{title}</h2>

      <div className='videos'>
        <ul>
          {id.map((item, index) => {
            return (
              <li key={index}>
                <ReactPlayer url={item} />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default GetFicheVideo
