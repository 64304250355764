import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

//scss
import '../../scss/fiche/ficheFile.scss'
//data
const getData = graphql`
  {
    img: allContentfulFicheImg {
      nodes {
        id
        images {
          title
          gatsbyImageData
        }
      }
    }
  }
`
const GetFicheImg = ({ id, title }) => {
  const data = useStaticQuery(getData).img.nodes.filter((i) => i.id === id)[0]
  console.log(data)
  return (
    <div className={`ficheFile`}>
      <h2 className='blockTitle'>{title}</h2>
      <div className='images'>
        {data.images.map((item, index) => {
          return (
            <GatsbyImage
              key={index}
              image={item.gatsbyImageData}
              alt={item.title}
            />
          )
        })}
      </div>
    </div>
  )
}

export default GetFicheImg
