import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
//scss
import '../../scss/product/productGalery.scss'
const ProductGalery = ({ galery, images }) => {
  const [title, setTitle] = useState(0)
  const [img, setImg] = useState(0)
  const [show, setShow] = useState(0)

  const handleChange = (id) => {
    setShow((show) => !show)
    setTitle((title) => images.filter((i) => i.id === id)[0].title)
    setImg((img) => images.filter((i) => i.id === id)[0].gatsbyImageData)
  }
  const init = () => {
    setShow((show) => 0)
    setTitle((title) => images[0].title)
    setImg((img) => images[0].gatsbyImageData)
  }
  return (
    <div className='productGalery zone'>
      <div className='container'>
        {galery
          ? galery.map((item, index) => {
              return (
                <GatsbyImage
                  image={item.gatsbyImageData}
                  alt={item.title}
                  key={index}
                  onClick={() => handleChange(item.id)}
                />
              )
            })
          : ''}
      </div>
      {show ? (
        <div
          className='overlay'
          onClick={() => init()}
          onKeyDown={() => init()}
          role='button'
          tabIndex={0}
        >
          <GatsbyImage image={img} alt={title} />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default ProductGalery
