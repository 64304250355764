import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
//content
import RichContent from '../content/RichContent'
//scss
import '../../scss/fiche/ficheText.scss'
//data
const getData = graphql`
  {
    text: allContentfulFicheText {
      nodes {
        id
        title
        content {
          raw
        }
      }
    }
  }
`
const GetFicheText = ({ id, title }) => {
  const data = useStaticQuery(getData).text.nodes.filter((i) => i.id === id)[0]
    .content
  return (
    <div className={`ficheText`}>
      <h2 className='blockTitle'>{title}</h2>
      <RichContent myRichContent={data} />
    </div>
  )
}

export default GetFicheText
