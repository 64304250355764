import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
//scss
import '../../scss/product/productTitle.scss'
const TitleProduitZone = ({ title, subtitle, img, divTitle }) => {
  return (
    <div className='productTitleZone zone'>
      <div className='container'>
        <div className='title'>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
          <Link
            to={`/contact`}
            className='button'
            state={{ product: title, sujet: 'Information produits' }}
          >
            Demande <br />
            d'information
          </Link>
        </div>
        <div className='img'>
          <GatsbyImage image={img.gatsbyImageData} alt={img.title} />
        </div>
      </div>
      <h3>{divTitle}</h3>
    </div>
  )
}

export default TitleProduitZone
