import React, { Component } from 'react'
import { Link } from 'gatsby'
import Sticky from 'react-sticky-el'

//component
import GetFicheIcon from '../fiche/GetFicheIcon'
import GetFicheFile from '../fiche/GetFicheFile'
import GetFicheText from '../fiche/GetFicheText'
import GetFicheImg from '../fiche/GetFicheImg'
import GetFicheVideo from '../fiche/GetFicheVideo'
//scss
import '../../scss/product/productFiche.scss'
export default class ProductFiche extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }
  state = {
    title: this.props.title,
    data: this.props.fiche,
    tab: 0,
  }

  changeTab(e) {
    this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    this.setState({ tab: parseInt(e.target.value) })
  }
  render() {
    return (
      <div className='productFiche zone' ref={this.myRef}>
        <div className='container'>
          <Sticky boundaryElement='.productFiche' className='sticky-title'>
            <ul>
              {this.state.data
                ? this.state.data.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={`tabTitle ${
                          index === this.state.tab ? 'active' : ''
                        }`}
                      >
                        <button
                          value={index}
                          onClick={this.changeTab.bind(this)}
                        >
                          {item.title}
                        </button>
                      </li>
                    )
                  })
                : ''}
            </ul>
            <Link
              to={`/contact`}
              className='button'
              state={{
                product: this.state.title,
                sujet: 'Information produits',
              }}
            >
              Demande d'information
            </Link>
          </Sticky>
          <div className='content'>
            {this.state.data
              ? this.state.data.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`tabContent ${
                        index === this.state.tab ? 'active' : ''
                      }`}
                    >
                      {item.internal ? (
                        item.internal.type === 'ContentfulFicheIcon' ? (
                          <GetFicheIcon id={item.id} title={item.title} />
                        ) : item.internal.type === 'ContentfulFicheText' ? (
                          <GetFicheText id={item.id} title={item.title} />
                        ) : item.internal.type === 'ContentfulFicheFile' ? (
                          <GetFicheFile id={item.id} title={item.title} />
                        ) : item.internal.type === 'ContentfulFicheImg' ? (
                          <GetFicheImg id={item.id} title={item.title} />
                        ) : item.internal.type === 'ContentfulFicheVideo' ? (
                          <GetFicheVideo id={item.urls} title={item.title} />
                        ) : (
                          'Erreur'
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  )
                })
              : ''}
          </div>
        </div>
      </div>
    )
  }
}
