import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
//content

//scss
import '../../scss/fiche/ficheFile.scss'
//data
const getData = graphql`
  {
    file: allContentfulFicheFile {
      nodes {
        id
        files {
          title
          file {
            url
          }
        }
      }
    }
  }
`
const GetFicheFile = ({ id, title }) => {
  const data = useStaticQuery(getData).file.nodes.filter((i) => i.id === id)[0]
  return (
    <div className={`ficheFile`}>
      <h2 className='blockTitle'>{title}</h2>

      <div className='files'>
        {data.files.map((item, index) => {
          return (
            <div key={index} className='file'>
              <a href={item.file.url} target='_bank'>
                {item.title}
              </a>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default GetFicheFile
